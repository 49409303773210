import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import _ from 'lodash';
import NotificationFormatter from '@uz/unitz-models/NotificationModel/formatter';
import NotificationModelRenderer from '@uz/unitz-models/NotificationModel/renderer';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            data: hook((ctx) => {
              return {
                email_template: ({ text, email }) => {
                  const content = NotificationFormatter.messageWithTranslation(ctx)(text, {
                    email: {
                      value: email,
                      isAction: true,
                      link: email,
                    },
                  });
                  return NotificationModelRenderer.renderHTML(ctx)(content);
                },

                resend_email: async () => {
                  const currentUser = ctx.get('authModel.currentUser');
                  // await currentUser?.sendEmailVerification();
                  if (currentUser) {
                    await fbFnsClient.getClient().post('triggers-tool-sendVerificationEmail', {
                      email: currentUser.email,
                    });
                  }
                },
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;

import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import FormLogin from '@uz/unitz-components-web/FormLogin';
import ToolBrandAuth from '@uz/unitz-tool-components/ToolBrandAuth';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import useCountdownTimer from '@vl/hooks/useCountdownTimer';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Button } from '@uz/unitz-components-web/Button';

import _ from 'lodash';

const CustomText = styled.div`
  p {
    color: ${gstyles.colors.main};
    margin-bottom: 0;
  }
`;

const Index = () => {
  // @TODO: validate missing required input
  const timer = useCountdownTimer({ second: 15 });
  return (
    <DIV className="pageContainer">
      <DIV className="isLoading">
        <div className="fixed z-10 w-full h-screen bg-white select-none top-20">
          <LoadingScreen />
        </div>
      </DIV>
      <div className="grid grid-cols-1 gap-5 my-0 lg:grid-cols-2 sm:my-11 md:mx-28 lg:mx-4 sm:wrapper app-row xl:mx-auto">
        <ToolBrandAuth />
        <div
          className="bg-white500 rounded-lg p-6"
          style={{
            boxShadow:
              '0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)',
          }}
        >
          <h3 className="text-2xl font-semibold text-left text-brandb500">
            {ctx.apply('i18n.t', 'ZoomToolVerifyEmail.title')}
          </h3>
          <div className="flex flex-col space-y-3 text-base text-main">
            <div>{ctx.apply('i18n.t', 'ZoomToolVerifyEmail.activation_notification')}</div>
            <CustomText>
              {ctx.apply('data.email_template', {
                text: ctx.apply('i18n.t', 'ZoomToolVerifyEmail.email'),
                email: ctx.apply('authModel.getUserEmail'),
              })}
            </CustomText>
            <div>{ctx.apply('i18n.t', 'ZoomToolVerifyEmail.check_text')}</div>
            <div>
              <span>{ctx.apply('i18n.t', 'ZoomToolVerifyEmail.description')}</span>
              <Button
                onClick={async () => {
                  // await new Promise((res) => setTimeout(res, 1000 * 5));
                  await ctx.apply('data.resend_email');
                  timer.start();
                }}
                disabled={!!timer.counting}
                type="link"
                className="text-brandb500 cursor-pointer"
              >
                {ctx.apply('i18n.t', 'ZoomToolVerifyEmail.resend')}
                {timer.counting ? ` (${timer.counter}s)` : ''}
              </Button>
            </div>
            <div>{ctx.apply('i18n.t', 'ZoomToolVerifyEmail.solutions.title')}</div>
            <div className="flex flex-col space-y-2">
              <div>{ctx.apply('i18n.t', 'ZoomToolVerifyEmail.solutions.content_1')}</div>
              <CustomText>
                {ctx.apply('data.email_template', {
                  text: ctx.apply('i18n.t', 'ZoomToolVerifyEmail.solutions.content_2'),
                  email: ctx.apply('authModel.getUserEmail'),
                })}
              </CustomText>
              <CustomText>
                {ctx.apply('data.email_template', {
                  text: ctx.apply('i18n.t', 'ZoomToolVerifyEmail.solutions.content_3'),
                  email: 'hi@unitz.biz',
                })}
              </CustomText>
            </div>
            <div>
              <CustomText>
                {ctx.apply('data.email_template', {
                  text: ctx.apply('i18n.t', 'ZoomToolVerifyEmail.contact'),
                  email: 'hi@unitz.biz',
                })}
              </CustomText>
            </div>
            <div>{ctx.apply('i18n.t', 'ZoomToolVerifyEmail.thanks')}</div>
            <div>{ctx.apply('i18n.t', 'ZoomToolVerifyEmail.sender')}</div>
          </div>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(Index);

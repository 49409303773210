import _ from 'lodash';
import moment from 'moment';
import currencyFormatter, { normalizeCurrencyTemp, normalizeNumber } from '../../mod-utils/currencyFormatter';
import i18n from 'i18n-js';

export const formatter = {
  reivewNum: () => (itemData) => {
    return _.get(itemData, 'avg_score', '');
  },
  titleTxt: (ctx) => (itemData) => {
    return _.get(itemData, 'name', '--');
  },

  message: (ctx) => (itemData, variable) => {
    const content = _.get(itemData, 'type.message_tpl', '');
    if (!variable || _.isEmpty(variable)) return content;

    const message = _.keys(variable).reduceRight((prev, curr) => {
      return prev.replace(
        `{{${curr}}}`,
        _.get(variable, `${curr}.isAction`)
          ? `<a style="font-weight:bold;text-decoration: none!important;" href="#">${_.get(
              variable,
              `${curr}.value`,
              ''
            )}</a>`
          : `<span >${_.get(variable, `${curr}.value`, '')}</span>`
      );
    }, content);

    return message || '';
  },

  messageWeb: (ctx) => (itemData, variable) => {
    const content = _.get(itemData, 'type.message_tpl', '');
    if (!variable || _.isEmpty(variable)) return content;

    const message = _.keys(variable).reduceRight((prev, curr) => {
      return prev.replace(
        `{{${curr}}}`,
        _.get(variable, `${curr}.isAction`)
          ? `<a style="font-weight:bold;text-decoration: none!important;" href="${_.get(
              variable,
              `${curr}.link`,
              ''
            )}">${_.get(variable, `${curr}.value`, '')}</a>`
          : `<span >${_.get(variable, `${curr}.value`, '')}</span>`
      );
    }, content);

    return message || '';
  },

  messageWithTranslation: (ctx) => (itemData, variable) => {
    const content = itemData ? itemData : '';
    if (!variable || _.isEmpty(variable)) return content;
    const message = _.keys(variable).reduceRight((prev, curr) => {
      return prev.replace(
        `{{${curr}}}`,
        _.get(variable, `${curr}.isAction`)
          ? `<a style="font-weight:bold;text-decoration: none!important;" href="${_.get(
              variable,
              `${curr}.link`,
              ''
            )}">${_.get(variable, `${curr}.value`, '')}</a>`
          : `<span >${_.get(variable, `${curr}.value`, '')}</span>`
      );
    }, content);
    const uselessWordsArray = ['missing', 'value'];
    let expStr = uselessWordsArray.join(' | ');
    return _.replace(_.replace(message, new RegExp(expStr, 'gi'), ''), /[\[\]']+/g, '') || '';
  },

  timeNow: (ctx) => (itemData) => {
    const locale = i18n.locale;
    const created_at = _.get(itemData, 'created_at', '');
    if (!created_at) return '';
    return moment(created_at)
      .locale(locale)
      .fromNow();
  },
  packageName: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'price.per_amount', '');
    if (!per_amount) return '';
    return ctx.apply('i18n.t', 'HomeAdvisor.namePackage', { time: normalizeNumber(per_amount) / 60 });
  },

  pricePerMin: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount');
    const per_amount = _.get(itemData, 'price.per_amount');
    if (!price_amount || !per_amount) return 0;
    return currencyFormatter(ctx)((parseInt(price_amount) / per_amount) * 60);
  },

  pricePerMinTitle: (ctx) => (itemData) => {
    const currency = _.get(itemData, 'price.price_currency');
    const unit = _.get(itemData, 'price.per_unit');
    if (!currency || !unit) return '';
    return ctx.apply('i18n.t', 'Advisor.pricePerMin', { currency: normalizeCurrencyTemp(ctx)(currency), unit: 'phút' });
  },
  notificationPackageDuration: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'price.per_amount', '');
    if (!per_amount) return '';
    return Math.round(per_amount / 60);
  },
  service: (ctx) => (itemData) => {
    const prefix = _.get(itemData, 'service.kind', '');
    return {
      kind: _.get(itemData, 'service.kind'),
      name: `${_.capitalize(prefix)}Service`,
      id: _.get(itemData, 'service.id'),
      service_id: _.get(itemData, 'id'),
      nameTxt: _.get(itemData, 'service.name'),
      icon: _.get(itemData, 'icon'),
      price: {
        ..._.get(itemData, 'price'),
        pricePerMinTxt: formatter.pricePerMin(ctx)(itemData),
        pricePerMinTitle: formatter.pricePerMinTitle(ctx)(itemData),
      },
      status: _.get(itemData, 'service.status') || false,
      status_kind: _.get(itemData, 'service.is_active') || false,
    };
  },

  counter: (ctx) => (itemData) => {
    const counter = _.get(itemData, '0.notification_counter.count', 0);

    if (!counter) return 0;

    return normalizeNumber(counter) >= 100 ? '99+' : counter;
  },
};

export default formatter;

import React from 'react';

const xss = require('xss');

export const renderer = {
  renderHTML: () => (itemData) => {
    if (!itemData) return '';
    const cleanHTML = xss(itemData);
    return React.createElement('p', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },
};

export default renderer;

import React from 'react';

import _ from 'lodash';

export const useCountdownTimer = (options = {}) => {
  const { second = 10 } = options;
  const [counter, $counter] = React.useState(second);
  const [counting, $counting] = React.useState(false);
  const ref = React.useRef({});
  _.assign(ref.current, {
    counter,
    $counter,
    second,
    counting,
    $counting,
  });

  const timer = {
    start: () => {
      ref.current.$counting(true);
      ref.current.$counter(second);
      ref.current.timer = setInterval(() => {
        ref.current.$counter(ref.current.counter - 1);
        if (ref.current.counter <= 0) {
          timer.stop();
        }
      }, 1000);
    },
    stop: () => {
      ref.current.$counting(false);
      clearInterval(ref.current.timer);
    },
    pause: () => {
      clearInterval(ref.current.timer);
    },
    counter: ref.current.counter,
    counting: ref.current.counting,
  };
  return timer;
};

export default useCountdownTimer;
